import React from "react";

function DailyResult() {

    return (
        <div>
            <h1>DailyResult</h1>
        </div>
    );
}

export default DailyResult;